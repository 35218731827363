import "./App.css";
import { useEffect, useState } from "react";

function App() {
  const [count, setCount] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      console.log(count);
      setCount((count + 1) % 2);
    }, 8000);
  }, [count]);

  return (
    <div className="container">
      <logo>poko.</logo>

      <div className={count == 0 ? "description display" : "description"}>
        Strona jest w trakcie budowy! Szykujemy dla Ciebie coś niesamowitego i
        ekscytującego.
      </div>

      <div className={count == 1 ? "description display" : "description"}>
        Website is under construction! We are preparing something amazing and
        exciting for You.
      </div>

      <div className="hello-bg">
      </div>
        <div className="hello">hello.</div>

      <a className="mail" href="mailto:paulina@pokostudio.pl">
        paulina@pokostudio.pl
      </a>

      <footer>
        Copyright © {new Date().getFullYear()} Poko. All rights reserved
      </footer>
    </div>
  );
}

export default App;
